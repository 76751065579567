






















































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import Fill8822Request from "@/dto/Fill8822Request";
import PhoneInput from "@/components/util/PhoneInput.vue";
import DocumentService from "@/services/DocumentService";
import RouteNames from "@/router/RouteNames";
import Workspaces from "@/state/Workspaces";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {PortalAddress, PortalInput, PortalCheckbox, PhoneInput}
})
export default class RequestF8822Modal extends Vue {

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

  private routes = RouteNames;

  private request = new Fill8822Request();

  public mounted(): void {
    if (!this.hasAdministrativePrivileges) {
        this.$router.push(this.routes.HOME);
    }
    this.loadInternalForm();
  }

	private loadInternalForm(): void {
		Application.startLoading();
		DocumentService.loadPreparedFormF8822b(this.documentId).then(
			success => {
				this.request.merge(success.data);
				Application.stopLoading();
			},
			error => processError(error, this)
		)
	}

  createForm(): void {
    ifValid(this, () => {
      Application.startLoading();
      DocumentService.fillF8822B(this.documentId, this.request).then(
        () => {
          Application.stopLoading();
          this.$router.push({name: this.routes.ARCHIVE_F8822, params: {type: "c", id: `${Workspaces.getCurrent.id}`}});
        },
        error => processError(error, this)
      )
    });
  }

  get documentId(): number {
    return Number.parseInt(this.$route.params.documentId);
  }

}
