import AddressDTO from "@/dto/AddressDTO";

export default class Fill8822Request {

    public taxExempt: boolean | null = null;

    public isDeclarationsExist: boolean | null = null;

    public isEmployeePlanReturn: boolean | null = null;

    public isBusinessLocation: boolean | null = null;

    public businessName: string | null = null;

    public ein: string | null = null;

    public oldMailingAddress: AddressDTO = new AddressDTO();

    public newMailingAddress: AddressDTO = new AddressDTO();

    public newBusinessLocation: AddressDTO = new AddressDTO();

    public newResponsibleName: string | null = null;

    public newResponsibleItin: string | null = null;

    public phone: string | null = null;

    public role: string | null = null;

    merge(data: PreFilledF8822bForm) {
        if (data.oldMailingAddress) {
            this.oldMailingAddress = data.oldMailingAddress
        }
        if (data.ein) {
            this.ein = data.ein
        }
        if (data.phone) {
            this.phone = data.phone
        }
        if (data.companyName) {
            this.businessName = data.companyName
        }
    }

}

export class PreFilledF8822bForm {
    public oldMailingAddress: AddressDTO | null = null;

    public ein: string | null = null;

    public companyName: string | null = null;

    public phone: string | null = null;

    public documentId: number | null = null;
}
